import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=74d44fff"
import script from "./Nav.vue?vue&type=script&lang=js"
export * from "./Nav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JbNavBurger: require('/buddy/finlandia/nuxt/client/jbcomponents/components/Jb/Nav/Burger.vue').default,JbNavItem: require('/buddy/finlandia/nuxt/client/jbcomponents/components/Jb/Nav/Item.vue').default,JbNavCollapse: require('/buddy/finlandia/nuxt/client/jbcomponents/components/Jb/Nav/Collapse.vue').default})
