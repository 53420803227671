export default (ctx, inject) => {
  inject('api', {
    getNurses: async (query, page = 1) => {
      let url = `api/nurses?page=${page}`
      if (query) {
        url += `&filter=${query}`
      }
      return await ctx.$axios.$get(url)
    },
    getSalesRepresentatives: async (query, page = 1) => {
      let url = `api/sales-representatives?page=${page}`
      if (query) {
        url += `&filter=${query}`
      }
      return await ctx.$axios.$get(url)
    },
    getDoctors: async (query, page = 1) => {
      let url = `api/doctors?page=${page}`
      if (query) {
        url += `&filter=${query}`
      }
      return await ctx.$axios.$get(url)
    },
    getBranches: async (query, page = 1) => {
      let url = `api/branches?page=${page}`
      if (query) {
        url += `&filter=${query}`
      }
      return await ctx.$axios.$get(url)
    },
    getDistricts: async (query, page = 1) => {
      let url = `api/districts?page=${page}`
      if (query) {
        url += `&filter=${query}`
      }
      return await ctx.$axios.$get(url)
    },
    getRegions: async (query, page = 1) => {
      let url = `api/regions?page=${page}`
      if (query) {
        url += `&filter=${query}`
      }
      return await ctx.$axios.$get(url)
    },
    getTags: async (query, page = 1, alwaysWith, type) => {
      let url = `api/tags?page=${page}&type=${type}`
      if (query) {
        url += `&filter=${query}`
      }
      if (alwaysWith) {
        url += `&always-with=${alwaysWith}`
      }
      return await ctx.$axios.$get(url)
    }
  })
}
