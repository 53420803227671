import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import { required, email, min, regex, numeric } from 'vee-validate/dist/rules'
import cs from 'vee-validate/dist/locale/cs.json'

localize('cs', cs)

extend('required', required)
extend('email', email)
extend('min', min)
extend('regex', regex)
extend('numeric', numeric)
// Add a rule.
extend('secret', {
  validate: value => value === 'example',
  message: 'This is not the magic word'
})

// Register it globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
